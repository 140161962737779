.NavHeader {
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100vw;
  background-color: #55585C;
  box-shadow: 0px 1px 2px rgba(238, 238, 238, 0.5), 
  0px 2px 4px rgba(255, 255, 255, 0.5), 
  0px 4px 8px rgba(255, 255, 255, 0.5), 
  0px 8px 16px rgba(255, 255, 255, 0.5);
  height: 100px;
}

.navIcon {
  transition: transform .2s;
}

.navIcon:hover {
  transform: scale(1.2);
}

.Logo {
  height: 55px;
  opacity: 90%;
  padding-left: 15px;
  transition: transform .2s;
}

.turned {
  transform:rotate(90deg);
}

.Logo:hover {
  transform: scale(1.1);
}

.navigation-menu {
  margin-left: auto;
  margin-right: 15px;
}

.SigLogo {
  width: 60%;
}

.XLogo {
  margin-right: 28px;
}

.SigLogoName {
  width: 70%;
  margin-left: 10px;
}

.hamburger {
  display: none;
}

@media screen and (max-width: 768px) {
  .hamburger {
    display: flex;
  }
  .Logo {
    display: none;
  }
  .navigation-menu ul {
    top: 100px;
    position: absolute;
    align-items: center;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(to right, #55585c 30%, #212529);
    display: none;
  }
  .navigation-menu li {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 30px;
    scale: 1.2;
  }
  .navigation-menu.expanded ul {
    display: block;
  }
}
.stackIcon {
  animation: pulse 2s infinite ease-in-out alternate;
}

@keyframes pulse {
  from { transform: scale(1); }
  to { transform: scale(1.2); }
}

.stackIcon:hover {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
  40% {transform: translateY(-10px);} 
  60% {transform: translateY(-5px);} 
}

.Techstack {
  margin-right: 80px;
}

@media screen and (max-width: 1400px) {
  .Techstack {
    margin-right: 0;
    margin-top: 60px;
  }
}

@media screen and (min-width: 1400px) {
  .stackIcon {
    margin-left: 10px;
    margin-right: 10px;
  }
}
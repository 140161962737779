.ProjectsContainer {
  padding-top: calc(100px + 5%);
  padding-bottom: 50px;
  overflow-x: hidden;
}

.ImageCard {
  max-height: 325px;
}

.PortfolioContainer {
  padding-right: 10%;
  padding-left: 10%;
  padding-top: 2%;
  padding-bottom: 2%;
}

.card {
  box-shadow: 2px 2px 10px 2px white;
  transition: transform .2s;
  text-decoration: none;
}

.card:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 540px) {
  .ProjectsContainer h1 {
    padding-top: 20px;
  }
}
.MainWrapper {
  height: 100vh;
  padding-top: 100px;
  background-image: linear-gradient(to right, #212529 40%, #ffffff00), url('../images/MainBackground.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.Description {
  margin-left: 60px;
}

.boxContainer {
  margin-right: 60px;
  margin-left: 150px;
}

.box {
  padding: 10px;
  background-color: rgb(255, 255, 255);
}

.avatar-wobble {
  border-radius: 250px 750px 250px 750px /
                 750px 250px 750px 250px;
  animation: wobble 8s ease-in-out alternate infinite;
}

@keyframes wobble {
  50% {
    border-radius: 750px 550px 350px 750px /
                   350px 750px 550px 450px;
  }
  100% {
    border-radius: 750px 250px 750px 250px /
                   250px 750px 250px 750px;
  }
}

.glow {
  -webkit-animation: glow 2s ease-in-out infinite alternate;
  -moz-animation: glow 2s ease-in-out infinite alternate;
  animation: glow 2s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px fff, 0 0 20px rgb(255, 255, 255)
  }
  to {
    text-shadow: 0 0 20px #fff
  }
}

.btn {
  transition: transform .2s;
}

.btn:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 992px) {
  .boxContainer {
    margin-left: 150px;
    margin-right: 150px;
  }
}

@media screen and (max-width: 840px) {
  .boxContainer {
    margin-left: 100px;
    margin-right: 100px;
  }
}

@media screen and (max-width: 700px) {
  .boxContainer {
    margin-left: 50px;
    margin-right: 50px;
  }
  .mobileCoverButton {
    font-size: 1.1rem;
  }
  .mobile-text {
    font-size: 0.95rem;
  }
  .mobile-text-button {
    font-size: 1.2rem;
  }
}